import { _axios } from '@/plugins/axios'

// 审批
export const apiProcessCheck = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/process/check`,
		method: method,
		data: dataJson
	})
}

// 归档
export const apiProcessFile = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/process/file`,
		method: method,
		data: dataJson
	})
}

// 审批人列表
export const apiGetChecker = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/process/checker/list`,
		method: method,
		data: dataJson
	})
}

// 设置节点及审批人
export const apiCheckSetting = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/process/checker/setting`,
		method: method,
		data: dataJson
	})
}

// 申请资料审核列表
export const apiGetCheckApplyList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/check/apply`,
		method: method,
		data: dataJson
	})
}

// 申请资料审核列表
export const apiGetLoanReject = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/customer/loanReject`,
		method: method,
		data: dataJson
	})
}

// 放款资料审核列表
export const apiGetCheckDisburseList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/list/check/disburse`,
		method: method,
		data: dataJson
	})
}

// 获得还款审核——案件基本信息
export const apiLoanTransactionInput = (id, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/input/${id}`,
		method: method,
		data: {}
	})
}

// 预览还款计划
export const apiLoanTransactionInputPreview = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/input/preview`,
		method: method,
		data: dataJson
	})
}

// 触发收车通知
export const apiRepossessionOrder = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input/repossessionOrder`,
		method: method,
		data: dataJson
	})
}

// 获得收车通知触发记录
export const apiFeeInputList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/fee/input/list`,
		method: method,
		data: dataJson
	})
}

// 客户费用录入
export const apiFeeInput = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input`,
		method: method,
		data: dataJson
	})
}

// 放款成功客户详情(汇总)
export const apiCustomerDetail = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/detail`,
		method: method,
		data: dataJson
	})
}

// 放款成功单个客户详情
export const apiCustomerInfo = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/info`,
		method: method,
		data: dataJson
	})
}

// 放款成功单个客户还款计划
export const apiRepaymentPlan = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/repaymentPlan`,
		method: method,
		data: dataJson
	})
}

// 放款成功单个客户费用录入记录
export const apiFeeList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/feeList`,
		method: method,
		data: dataJson
	})
}

// 放款成功单个客户其他费用列表查询
export const apiFeeOtherList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/otherFeeList`,
		method: method,
		data: dataJson
	})
}

// 放款成功单个客户还款明细查询
export const apiRepaymentDetails = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/disburse/customer/repaymentDetails`,
		method: method,
		data: dataJson
	})
}

// 同意/拒绝平账
export const apiHandleTransaction = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loanTransaction/handleTransaction`,
		method: method,
		data: dataJson
	})
}

// 获得还款审核——行为记录
export const apiProcessHistory = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loanTransaction/processHistory`,
		method: method,
		data: dataJson
	})
}

// 审核设置删除

export const apiCheckerDel = (storeId, loanType, id, method = 'post') => {
	return _axios({
		url: `/car/loan/process/checker/delete/${storeId}/${loanType}/${id}`,
		method: method,
		data: {}
	})
}

// 触发罚息
export const apiPostPenalty = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/fee/input/penalty`,
		method: method,
		data: dataJson
	})
}

// 获取评论列表
export const apiCommentList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/comment/list?loanId=${dataJson}`,
		method: method
	})
}

// 贷款单评论
export const apiCommentAdd = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/comment/add`,
		method: method,
		data: dataJson
	})
}

// 获取Loan Brief
export const apiFindLoanBrief = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/findLoanBrief?loanId=${dataJson}`,
		method: method
	})
}

// 保存Loan Brief
export const apiSaveLoanBrief = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/loan/apply/saveLoanBrief`,
		method: method,
		data: dataJson
	})
}

// 获取net或gross金额
export const apiAmountCalculate = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/loan/apply/amount/calculate`,
		method: method,
		data: dataJson
	})
}
